import { IngestionMessage, MessageScope } from "../asset-service-api";
import { ITrack } from "../Projects/MapData";
import { FormatDuration } from "../Formatting";
import { Resource } from "../api";
import { useProjects, hasMultipleTrackGroups } from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export const useDurationDeltaValidators = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const { project, getMappedTrack, getMappedResources, isOverrideChecked} = useProjects(projectId!);
    const multipleTrackGroups = hasMultipleTrackGroups(project!);
    const scope = multipleTrackGroups ? MessageScope.TrackGroup : MessageScope.Track;
    const durationDeltaWarning = (): IngestionMessage[] => {
        const Tolerance = 5;
        const RestrictedTolerance = 10;

        function isDurationEqual(item: ITrack, iAudioResource: Resource): boolean {
            const delta = multipleTrackGroups
                ? (iAudioResource?.audioInfo?.duration ?? 0) * 0.10 // AT-3271 10% for Vinyl/Cassette
                : Tolerance; // 5 seconds for all other types
            return (Math.abs((item?.duration ?? 0) - (iAudioResource?.audioInfo?.duration ?? 0))) < delta;
        }

        const mappedResources = getMappedResources();
        const errorResources = mappedResources.filter(x => !isDurationEqual(getMappedTrack(x)!, x));
        return errorResources.map(x => {
            const mappedTrack = getMappedTrack(x);
            const d1 = x.audioInfo?.duration ?? 0;
            const d2 = mappedTrack?.duration ?? 0;
            const fileDur = FormatDuration(d1);
            const trackDur = FormatDuration(d2);
            const durDiff = FormatDuration(Math.abs(d1 - d2));
            let tolerance = Tolerance;
            let warningMessageType = "Warning";

            if (Math.abs(d1 - d2) > RestrictedTolerance) {
                tolerance = RestrictedTolerance;

                if (!multipleTrackGroups) {
                    warningMessageType = isOverrideChecked() ? "RestrictedResolved" : "RestrictedResolvable";
                }
            }
            return {
                message: multipleTrackGroups
                    ? `Difference between file duration and side (track group) duration is ${durDiff}.`
                    : `Duration discrepancy (${durDiff}) between Asset (${fileDur}) and ${scope} #${mappedTrack?.number} (${trackDur}) exceeds ${tolerance} seconds.${(tolerance === RestrictedTolerance) ? " Ingest Restricted." : ""}`,
                messageType: warningMessageType,
                source: {
                    scope: scope,
                    stage: "local",
                    target: mappedTrack?.number.toString(),
                    id: "601950E4-080F-4FB7-84A8-7D66C0879A96",
                },
            } as IngestionMessage;
        }).sort((a, b) => Number(a.source?.target) - Number(b.source?.target));
    };
    return { durationDeltaWarning };
};
