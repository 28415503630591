import SettingsIcon from "@mui/icons-material/Settings";
import {ChangeEvent, useEffect, useState} from "react";
import {Form, Modal, Row} from "react-bootstrap";
import { useStore, Preferences } from "./State/zustandStore";

export type settingId = "LimitWidth" | "UseScroll"

interface Props {
    label: string
    accessor: keyof Preferences
    checked: boolean
}

export const BoolSetting = (props: Props) => {
    const userPreferences = useStore().preferences;

    function mdClick(e: ChangeEvent<HTMLInputElement>, accessor: keyof Preferences) {
        userPreferences.setPreference(accessor, e.target.checked);
    }
    return <Form.Group as={Row} className="mb-3 ps-3">
        <Form.Check
            type="switch"
            id={`custom-switch${ props.accessor}`}
            label={props.label}
            onChange={(e) => mdClick(e, props.accessor)}
            defaultChecked={props.checked}
        />
    </Form.Group>;
};

export const UserPreferencesButton = () => {
    const userPreferences = useStore().preferences;
    const [settingsVisible, setSettingsVisible] = useState(false);

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
            if (e.code === "Escape") {
            }
        };

        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);

    function onHide() {
        setSettingsVisible(false);
    }
    return (
        <>
            <SettingsIcon className={"me-2"} onClick={() => setSettingsVisible(true)} />
            <Modal onHide={onHide} show={settingsVisible}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>User Settings</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <BoolSetting label={"Use Full Width"} accessor={"useFullWidth"} checked={userPreferences.useFullWidth} />
                        <BoolSetting label={"Enable logging"} accessor={"enableLog"} checked={userPreferences.enableLog} />
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};
