import { Project } from "../api";
import { ProductTypeEnum } from "./productTypeEnum";

function isProductCd(project: Project) {
    const releaseInfo = project.releaseInfo;

    switch (releaseInfo?.configuration) {
    case "CD album":
    case "CD album enhanced":
    case "CD EP":
    case "CD EP enhanced":
    case "CD maxisingle":
    case "CD maxisingle 3\"":
    case "CD maxisingle enhanced":
    case "CD single":
    case "CD single 3\"":
    case "CD single enhanced":
    case "HQ CD":
    case "MQA-CD":
    case "SHM-CD":
    case "UHQ CD":
        return true;
    default:
        return false;
    }
}

export function getProductType(project: Project): ProductTypeEnum {
    const releaseInfo = project?.releaseInfo;

    if (releaseInfo?.configuration) {
        if (isProductCd(project))
            return ProductTypeEnum.CDAudio;

        const configuration = releaseInfo.configuration.toLowerCase();

        if (configuration.includes("vinyl") &&
            !configuration.includes("combi-pack")) {
            return ProductTypeEnum.Vinyl;
        }

        if (configuration.startsWith("mc ")) {
            return ProductTypeEnum.Cassette;
        }

        if (configuration.startsWith("cd ") &&
            !configuration.includes(" + ") &&
            !configuration.includes(" plus ")) {
            return ProductTypeEnum.CDAudio;
        }

        if (configuration.startsWith("dvd") &&
            !configuration.includes(" + ") &&
            !configuration.includes(" plus ") &&
            !configuration.includes("combi-pack")) {
            return ProductTypeEnum.DVD;
        }

        if (configuration.includes("blu-ray disc") &&
            !configuration.includes(" + ")) {
            return ProductTypeEnum.BluRay;
        }

        if (configuration.includes("surround") &&
            releaseInfo.resolutionType === "Dolby Atmos") {
            return ProductTypeEnum.DolbyAtmos;
        }

        if (!configuration.includes("video") &&
            !configuration.includes("+ lp") &&
            !configuration.includes("surround") &&
            (configuration.startsWith("ealbum") ||
             configuration.startsWith("esingle") ||
             configuration.startsWith("efixedplaylist") ||
             configuration.startsWith("esnippet") ||
             configuration.startsWith("emix")))
        {
            return configuration.includes(" hd")
                ? ProductTypeEnum.HDAudio
                : ProductTypeEnum.SDAudio;
        }
    }
    return ProductTypeEnum.Unknown;
}
