import { v4 as uuidv4 } from "uuid";
import { ButtonGroup, Col, Container, Row, Table } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import {
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import { Resource, ResourceType, Track } from "../../../../api";
import { useProjects, hasMultipleTrackGroups, hideAutoMap, allowMappingFiles } from "../../../../Behaviors/projects";
import { GetMd5Hash } from "../../../../checksum";
import { AutoMapButton } from "../../../../Buttons/Buttons";
import { useStore } from "../../../../State/zustandStore";
import { Dragon } from "../../../../dragon";
import { snapCenterToCursor } from "../../../../dnd-kit-utilities/snapCenterToCursor";
import { TrackList } from "../../../TrackList";
import { ResourceMode, getResourceListMode } from "../../../../Behaviors/projectTools";
import { DdpmsParse } from "../../../../DdpmsParser";
import { FileSystemFileHandlePath } from "../../../../dragon.filesystem";
import { useAsperaApi } from "../../../../aspera/aspera";
import { ParseWavFile } from "../../../../ParseWavFile";
import { AudioResourceList } from "./AudioResourceList";
import { DraggableAudioResource } from "./DraggableAudioResource";

async function createResource(file: FileSystemFileHandlePath, resourceType: ResourceType) {
    const fileSize = (await file.getFile()).size;
    const md5 = await GetMd5Hash(file);
    const resource: Resource = {
        filename: file.name,
        id: uuidv4(),
        localPath: file.systemPath,
        resourceType: resourceType,
        md5: md5,
        fileSize: fileSize,
        relativePath: file.relativePath,
    };
    return resource;
}

async function parseCmf(bdResource: FileSystemFileHandlePath) {
    const file = await bdResource.getFile();
    const cmfXml = await file.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(cmfXml, "application/xml");

    const layerInfo = doc.getElementsByTagName("LayerInfo")[0] as HTMLElement;
    const layerCount = layerInfo?.getAttribute("Total");

    switch (layerCount) {
    case "1":
        return ResourceType.CmfBd25;
    case "2":
        return ResourceType.CmfBd50;
    default:
        return ResourceType.File;
    }
}

async function isBundleFileAllow(file: FileSystemFileHandlePath) {
    const disallowedExtensions = ["exe", "js", "md5"];
    const disallowedFileNames = ["ingestion_ticket.xml", "wnticket.txt", "asset_map.xml", "pqlog.xml"];

    const extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLocaleLowerCase();

    if (disallowedExtensions.includes(extension)) {
        return false;
    }

    if (disallowedFileNames.includes(file.name.toLocaleLowerCase())) {
        return false;
    }

    if (file.name.startsWith(".")) {
        return false;
    }

    if ((await file.getFile()).size < 1) {
        return false;
    }
    return true;
}

function autoDetectUpc(filenames: string[]): string | null {
    const regex = /(?:[^\d]|^)(\d{13}|\d{14})(?:[^\d]|$)/;

    for (const filename of filenames) {
        // Replace separators with spaces for better matching
        const sanitizedFilePath = filename.replace(/[-_.,;\s]+/g, " ");
        // Search for the sequence of 13 or 14 consecutive digits in the sanitized file path
        const match = sanitizedFilePath.match(regex);

        // Return the match if found, otherwise return null
        if (match) {
            return match[1];
        }
    }
    return null; // Return null if no matching filename is found
}

export const DigitalAudioTrackMap = () => {
    const [activeDragItem, setActiveDragItem] = useState((): Resource | null => null);
    const store = useStore();
    const userPreferences = store.preferences;
    const projectIdRef = useRef<string>("");
    const projectHasReleaseInfoRef = useRef<boolean>(false);
    const projectResourcesRef = useRef<Resource[] | null | undefined>(null); // AT-3773

    projectIdRef.current = store.projects.selectedProjectId!;

    const projectId = store.projects.selectedProjectId!;
    const {
        mapResourceToTrack,
        unmapResource,
        getFilesAddedEvent,
        addResources,
        project,
    } = useProjects(projectId);
    const projectHasReleaseInfo = project?.releaseInfo !== undefined;

    projectHasReleaseInfoRef.current = projectHasReleaseInfo;

    projectResourcesRef.current = project?.resources;

    const aspera = useAsperaApi();
    const autoUploadResourceIds = [] as string[];

    function onError(error: Error) {
        toast.error(error.message);
    }

    // TODO: consider refactoring this into DraftView.tsx
    async function filesDropped(event: Dragon.FilesDroppedEvent) {
        // this function creates a closure over the projects hook when the
        // dnd handler is registered, so the project id inside the hook will be stale.
        // Get the project id selected at the time files are dropped.
        const projectId = projectIdRef.current;
        const upcIsSelected = projectHasReleaseInfoRef.current;
        const ddpmsFilename = "ddpms";
        const ddpIdFilename = "ddpid";
        const bdCmdFilename = "bdcmf.cmf";
        let ignoredFileCount = 0;
        const droppedFiles = [...event.files].sort((a, b) => a.name.localeCompare(b.name));
        const wavFiles = droppedFiles.filter(x => x.name.toLowerCase().endsWith(".wav") || x.name.toLocaleLowerCase().endsWith(".dsf"));
        const processingToast = toast("Analyzing...", { autoClose: false }) as string;
        const newResources: Resource[] = [];
        const mode = getResourceListMode(projectResourcesRef.current);

        let index = 0;

        if (mode! & ResourceMode.TrackAsset || (wavFiles.length > 0 || [...event.files].find(r => !r.relativePath))) {
            // Individual Files are dropped!
            ignoredFileCount = [...event.files].filter(x => !x.name.toLowerCase().endsWith(".wav") && !x.name.toLowerCase().endsWith(".dsf")).length;

            for (const wav of wavFiles) {
                const file = await wav.getFile();
                const mediaInfo = await ParseWavFile(wav)
                    .catch((error) => {
                        toast.error(`${error}`);
                    });

                if (mediaInfo) {
                    // Validate integrity of the results based on calculated size and duration
                    const durationMs = Math.round((mediaInfo.FrameCount as number * 1000) / (mediaInfo.SampleRate as number));
                    const calculatedSize = Number(mediaInfo.FileSize); // convert to a number

                    if ((calculatedSize !== file.size) || (mediaInfo.FrameCount > 0 && mediaInfo.DurationMS !== durationMs)) {
                        // size or duration does not match, continue with the next file in the for loop.
                        toast.error(`Could not find audio track in ${wav.name}`);
                        continue;
                    }

                    const md5 = await GetMd5Hash(wav);
                    const newFile: Resource = {
                        filename: wav.name,
                        id: uuidv4(),
                        localPath: wav.systemPath,
                        resourceType: ResourceType.Audio,
                        audioInfo: {
                            bit_depth: mediaInfo.BitDepth as number,
                            sample_rate: mediaInfo.SampleRate as number,
                            duration: mediaInfo.DurationMS / 1000 as number,
                            channels: mediaInfo.Channels?.toString(),
                            isDolby: mediaInfo.Channels > 2,
                        },
                        md5: md5,
                        // relativePath not used for wav/dsd files
                    };

                    index++;
                    newResources.push(newFile);
                    toast.update(processingToast, {
                        render: ` Analyzing ${index} of ${wavFiles.length}`,
                        type: toast.TYPE.INFO, autoClose: false,
                    });
                } // endof if (mediaInfo)...
            } // endof for each wave...
        } else {
            const files = droppedFiles;
            // A folder is dropped!
            // Only one level of subfolder is allowed.
            // Sort the files by path, which will make the topmost folder first.
            // The first character of the path is the environment path separator.
            // Discard anything with more thanb two path separators.
            const sortedFiles = files.sort((a, b) => {
                if (a.relativePath < b.relativePath) {
                    return -1;
                }

                if (a.relativePath > b.relativePath) {
                    return 1;
                }
                return 0;
            });
            const pathSeparator = sortedFiles[0].relativePath[0];
            let allowedFiles = files.filter(f => f.relativePath.split(pathSeparator).length <= 3);
            const allowedFilesCount = allowedFiles.length;

            const filterResults = await Promise.all(allowedFiles.map(isBundleFileAllow));

            allowedFiles = allowedFiles.filter((value, index) => filterResults[index]);

            ignoredFileCount = allowedFilesCount - allowedFiles.length;

            const bdResource =
                allowedFiles.find(r => r.name.toLocaleLowerCase() === bdCmdFilename) ??
                allowedFiles.find(r => r.name?.match(/^\d{14}_BD(25|50)_BDCMF.CMF$/gmi));

            // When a ddpms file is dropped, parse it and set the name of the PQ descriptor file
            const ddpMsFile = allowedFiles.find(f => f.name.toLowerCase() === ddpmsFilename);
            let pqDescriptorFile;
            let pqData;
            let encodedCdText;
            let cdTextFile;

            if (ddpMsFile) {
                const ddpms = await DdpmsParse(ddpMsFile);

                if (ddpms) {
                    const pqDescriptor = ddpms.find(d => d.SubcodeDescriptor === "PQ DESCR");

                    if (pqDescriptor) {
                        const pqDescriptorName = pqDescriptor.DataStreamIdentifier.trim();

                        pqDescriptorFile = allowedFiles.find(a => a.name === pqDescriptorName);

                        if (pqDescriptorFile) {
                            const file = await pqDescriptorFile.getFile();

                            pqData = await file.text();
                        }
                    }

                    const cdText = ddpms.find(d => d.SubcodeDescriptor.trimEnd() === "CDTEXT");

                    if (cdText) {
                        const cdTextName = cdText.DataStreamIdentifier.trim();

                        cdTextFile = allowedFiles.find(a => a.name === cdTextName);

                        if (cdTextFile) {
                            const file = await cdTextFile.getFile();
                            const data = await file.arrayBuffer();

                            encodedCdText = Buffer.from(data).toString("base64");
                        }
                    }
                }
            }

            ignoredFileCount = files.length - allowedFiles.length;

            for (const file of allowedFiles) {
                if (file === ddpMsFile) {
                    const ddpMs = await createResource(file, ResourceType.DdpMs);

                    newResources.push(ddpMs);
                    autoUploadResourceIds.push(ddpMs.id!);
                } else if (ddpMsFile && file === pqDescriptorFile) {
                    const pqResource = await createResource(file, ResourceType.DdpPq);

                    pqResource.pqPacketString = pqData;
                    newResources.push(pqResource);
                    autoUploadResourceIds.push(pqResource.id!);
                } else if (ddpMsFile && file === cdTextFile) {
                    const cdTextResource = await createResource(file, ResourceType.DdpText);

                    cdTextResource.cdTextString = encodedCdText;
                    newResources.push(cdTextResource);
                } else if (ddpMsFile && file.name?.toLocaleLowerCase() === ddpIdFilename) {
                    const ddpIdResource = await createResource(file, ResourceType.DdpId);

                    newResources.push(ddpIdResource);
                    autoUploadResourceIds.push(ddpIdResource.id!);

                } else if (file === bdResource) {
                    const bdType = await parseCmf(bdResource);
                    const bdCmf = await createResource(file, bdType);

                    newResources.push(bdCmf);
                    autoUploadResourceIds.push(bdCmf.id!);
                } else {
                    const newResource = await createResource(file, ResourceType.File);

                    newResources.push(newResource);
                }

                index++;

                toast.update(processingToast, {
                    render: ` Analyzing ${index} of ${files.length}`,
                    type: toast.TYPE.INFO, autoClose: false,
                });

            }
        }

        toast.dismiss(processingToast);

        const filesAddedEvent = getFilesAddedEvent(newResources);

        if (filesAddedEvent.tooManyResources > 0) toast.error(`${filesAddedEvent.tooManyResources} files could not be added. (The list is full.)`);

        if (ignoredFileCount > 0) toast.warn(`${ignoredFileCount} ${newResources.find(r => r.resourceType === ResourceType.File) ? "" : "(non-wav)"} files were ignored.`);

        if (filesAddedEvent.resourcesReplaced > 0) toast.info(`${filesAddedEvent.resourcesReplaced} files were updated.`);

        if (filesAddedEvent.resourcesAdded > 0) toast.info(`${+filesAddedEvent.resourcesAdded} files were added.`);

        if (!upcIsSelected) {
            const distinctFolderNames = [...new Set(droppedFiles.flatMap((p) => p.entryPath))];
            const fileNames = droppedFiles.flatMap((p) => p.name);
            const allNames = distinctFolderNames.concat(fileNames);
            const upc = autoDetectUpc(allNames);

            if (upc != null) {
                // a valid UPC was detected and user has not entered a UPC yet
                store.projects.setDetectedUpc(upc);
            }
        }

        try {
            const project = await addResources(projectId, newResources);
            let resources = [] as Resource[];

            resources = project.resources?.filter(r => autoUploadResourceIds.includes(r.id!)) ?? [];

            if (resources.length > 0) {
                await aspera.uploadResources(resources, projectId, project.resourcePathKey!);
            }
        }
        catch (ex) {
            console.error(ex);
        }

    }

    const { wire, unWire } = Dragon.setDropTarget(".audio-resource-list", filesDropped, onError);

    useEffect(() => {
        wire();
        return unWire;
    }
    , [wire, unWire]);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                delay: 150,
                tolerance: 400,
            },
        }));

    function handleDragStart(event: DragStartEvent) {
        // set the activeDragItem so that the overlay will paint.
        const r = project?.resources?.find(x => x.id === event.active.id);

        setActiveDragItem(r || null);
    }

    async function handleDragEnd(event: DragEndEvent) {

        if (!allowMappingFiles(project!)) {
            // Mapping of individual files is not allowed for CDAudio/DVD/BluRay configurations.
            return;
        }

        if (event.collisions) {
            const distinctGroups = [...new Set(project?.releaseInfo?.trackGroups?.map(t => t.groupNumber))];
            const multipleTrackGroups = hasMultipleTrackGroups(project!);

            const id = event.collisions[0].id;

            if (id === -1) {
                const resource = project?.resources?.find(x => x.id === event.active.id);

                if (resource) {
                    await unmapResource(resource.id!);
                }
            }

            const trackOrGroupNumber = !multipleTrackGroups ?
                project?.releaseInfo?.trackGroups.reduce((a, c) => c.tracks, [] as Track[]).find(x => x.number === id)?.number
                : distinctGroups.find(x => x === id);

            if (trackOrGroupNumber) {
                const resource = project?.resources?.find(x => x.id === event.active.id.toString());

                await mapResourceToTrack(resource?.id!, trackOrGroupNumber);
            }

            setActiveDragItem(null);
        }
    }

    if (!project) return (<></>);

    const mode = getResourceListMode(project?.resources ?? []);
    const hideAutoMapButton = hideAutoMap(project!);
    return (
        <>
            <Container fluid={userPreferences.useFullWidth} className={"wrapper"} id={"mainContain"}>
                <div className={"modal-blocked"}>
                    <Row className="track-map-header">
                        <Col lg={6}>
                            <h3>{mode & ResourceMode.TrackAsset ? "Unmapped Audio Files" : "Selected Files"}</h3>
                        </Col>
                        <Col lg={6}>
                            <h3>Product Track List</h3>
                        </Col>
                    </Row>

                    <Row xl={12} className="mt-3 track-map">
                        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}
                            modifiers={[snapCenterToCursor]}>
                            {createPortal(
                                <DragOverlay>
                                    {activeDragItem ?
                                        (
                                            <Table style={{
                                                width: "300px!important",
                                                backgroundColor: "white",
                                            }} size="sm">
                                                <tbody>
                                                    <DraggableAudioResource isOverlay={true} projectId={projectId}
                                                        audioResource={activeDragItem} />
                                                </tbody>
                                            </Table>
                                        ) : null}
                                </DragOverlay>
                                , document.body)}

                            <Col lg={hideAutoMapButton ? 6 : 5} className={`table-container--scroll ${hideAutoMapButton ? "pe-3" : ""}`}>
                                <AudioResourceList />
                            </Col>
                            {!hideAutoMapButton && (
                                <Col lg={1} className="text-center">
                                    <ButtonGroup vertical className="mt-1 mb-2">
                                        <ButtonGroup vertical className="mt-1 mb-2">
                                            <AutoMapButton />
                                        </ButtonGroup>
                                    </ButtonGroup>
                                </Col>)
                            }
                            <Col lg={6} className="table-container--scroll">
                                <TrackList />
                            </Col>
                        </DndContext>
                    </Row>
                </div>
            </Container>
        </>
    );
};
