export function FormatDuration(seconds: number | null | undefined) {
    if (!seconds || seconds <= 0) {
        return "";
    }

    const hours = Math.floor(seconds / (3600));
    const minutes = Math.floor((seconds / 60) - (hours * 60));
    const secondsM = Math.floor(seconds - (minutes * 60) - (hours * 3600));
    const milliSeconds = Math.round(10 * (seconds - (secondsM + (minutes * 60) + (hours * 3600))));
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${secondsM.toString().padStart(2, "0")}${(milliSeconds === 0 ? "" : `.${milliSeconds}`)}`;
    // return ( (minutes < 10) ? "0"+minutes : minutes )+ ":"+ (seconds < 10) ?"0"+seconds : seconds
}

export function FormatTitle(title: string, versionTitle?: string | null | undefined) {
    // append versionTitle (if any) to the title
    return versionTitle ? `${title} - ${versionTitle}` : title;
}
