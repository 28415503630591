import { Col, Container, Row } from "react-bootstrap";
import { ProductSummary } from "../../ProductInfo/ProductSummary";
import { IngestList } from "../IngestList";
import { IngestProgressBar } from "../ProgressBar";
import { Messages } from "../Messages";
import { DuplicateButton, ViewInAstButton } from "../../Buttons/Buttons";
import { useStore } from "../../State/zustandStore";
import { useProjects } from "../../Behaviors/projects";
import { isAudioMode } from "../../Behaviors/projectTools";

export const CompletedView = () => {
    const userPreferences = useStore().preferences;
    const projectId = useStore().projects.selectedProjectId;
    const resources = useProjects(projectId).getSelectedProjectResources();
    return (
        <Container fluid={userPreferences.useFullWidth} className={"wrapper"} id={"mainContain"}>
            <Row className="mt-3">
                <Col>
                    <h3>Product Info</h3>
                </Col>
            </Row>
            <Row className=" bg-light mt-2 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <ProductSummary allowEdit={false} visible={true} />
                </Col>
            </Row>

            <Row className=" bg-light mt-3 mb-3 pt-1 " style={{ minHeight: "60px" }}>
                <Col>
                    <IngestProgressBar />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"float-left"}>
                        { isAudioMode(resources) ?
                            <>Create a new draft using these files <DuplicateButton /></> :
                            null
                        }

                    </div>
                </Col>
                <Col>
                    <ViewInAstButton />
                </Col>
            </Row>
            <Row className=" bg-white mt-3 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <IngestList />
                </Col>
            </Row>
            <Row className=" bg-white mt-3 mb-3 pt-1 " style={{ minHeight: "140px" }}>
                <Col>
                    <Messages />
                </Col>
            </Row>

        </Container>
    );
};
