import {
    Project, Resource, ResourceType,
} from "../api";
import {
    Job, CommandAction, ExecutionState,
} from "../asset-service-api";

function isFileMode(resources: Resource[] | null | undefined) {
    if (resources) {
        return resources?.every(r => r.resourceType === ResourceType.Audio);
    }
    return true;
}

function isResourceMapped(resource: Resource, project: Project)
{
    if (project.trackResourceMap) {
        return project.trackResourceMap?.find(x => x.resourceId === resource.id) !== undefined;
    }
    return false;
}

function getMappedResources(project: Project): Resource[] {
    const resources = project.resources;

    if (isFileMode(resources)) {
        return Array.from(resources!.filter(x => isResourceMapped(x, project)).values());
    }
    return [];
}
function getAllResources(project: Project): Resource[] {
    return project.resources!;
}

enum ProjectState {
        Unknown,
        Loading,
        Draft,
        Processing,
        Completed,
        NeedsAttention
    }

const getProjectState = (job: Job) => {
    const action = job?.command?.action;

    if (job?.id === null) {
        return "Draft";
    }

    if (action === CommandAction.Validate || action === null)
        return ProjectState.Draft;

    if (action === CommandAction.Ingest) {
        const state = job.state;

        if (state === ExecutionState.Validating ||
                state === ExecutionState.Processing ||
                state === ExecutionState.CreatingBundle ||
                state === ExecutionState.Created ||
                state === ExecutionState.Validated ||
                state === ExecutionState.Bundled) return ProjectState.Processing;

        if (state === ExecutionState.Completed) return ProjectState.Completed;

        if (state === ExecutionState.Failed ||
                state === ExecutionState.ValidationFailed ||
                state === ExecutionState.Exception ||
                state === ExecutionState.Suspended) return ProjectState.NeedsAttention;

        console.error("Unknown state", state, action);
    }
    return ProjectState.Loading;
};

enum ResourceMode {
    Unknown = 0,
    File = 1 << 0,
    TrackAsset = 1 << 2,
    DVD9 = 1 << 3,
    UHD = 1 << 4,
    DDP = 1 << 5,
    DVD5 = 1 << 6,
    BD25 = 1 << 7,
    BD50 = 1 << 8,
}

const getSubFolders = (files: Resource[]) => {
    // Sort the files by path, which will make the topmost folder first.
    // The first character of the path is the environment path separator.
    // Discard anything with more thanb two path separators.
    const sortedFiles = files.sort((a, b) => {
        if (a.relativePath! < b.relativePath!) {
            return -1;
        }

        if (a.relativePath! > b.relativePath!) {
            return 1;
        }
        return 0;
    });
    const pathSeparator = sortedFiles[0].relativePath?.[0] || "\\";
    const filesInSubFolders = sortedFiles.filter(f => f.relativePath?.split(pathSeparator).length === 3);
    const subFolders = new Set(filesInSubFolders.map(f => f.relativePath!));
    return subFolders;
};

const getResourceListMode = (resources?: Resource[] | null | undefined) => {
    if (resources && resources.length > 0) {
        if (isFileMode(resources)) {
            return ResourceMode.TrackAsset;
        } else {
            const subFolders = getSubFolders(resources);

            if (subFolders.size > 0) {
                if (
                    subFolders.size === 2 &&
                    [...subFolders.values()].every(f => f.match(/[\\/]\d{14}_DVD9_layer[01]_V\d$/i))
                ) {
                    return ResourceMode.File | ResourceMode.DVD9;
                } else {
                    return ResourceMode.File | ResourceMode.Unknown;
                }
            } else if (resources.find(r => r.filename?.toUpperCase() === "UHDBD.CMF")) {
                return ResourceMode.File | ResourceMode.UHD;
            } else if (resources.find(r => r.resourceType === ResourceType.CmfBd25)) {
                return ResourceMode.File | ResourceMode.BD25;
            } else if (resources.find(r => r.resourceType === ResourceType.CmfBd50)) {
                return ResourceMode.File | ResourceMode.BD50;
            } else if (resources.find(r => r.filename?.toUpperCase() === "CONTROL.DAT")) {
                return ResourceMode.File | ResourceMode.DVD5;
            } else if (
                resources.find(r => r.resourceType === ResourceType.DdpMs) &&
                resources.find(r => r.resourceType === ResourceType.DdpId)
            ) {
                return ResourceMode.File | ResourceMode.DDP;
            }
            return ResourceMode.File;
        }
    }
    return ResourceMode.Unknown;
};

export {
    isFileMode as isAudioMode,
    isResourceMapped,
    getMappedResources,
    getAllResources,
    getProjectState,
    getResourceListMode,
    ProjectState,
    ResourceMode,
};
