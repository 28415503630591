import {Col, Container, Row} from "react-bootstrap";
import { useStore } from "../../../../State/zustandStore";
import { useProjects } from "../../../../Behaviors/projects";
import { ResourceMode, getResourceListMode } from "../../../../Behaviors/projectTools";
import {FileList} from "./FileList";
import {DdpTrackList} from "./DdpTrackList";

function getResourceListHeader(mode: ResourceMode) {
    const type = mode ^ ResourceMode.File;
    return `Selected Files (${ResourceMode[type]})`;
}

export const DdpTrackMap = () => {
    const userPreferences = useStore().preferences;
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const { project } = useProjects(projectId);
    const mode = getResourceListMode(project?.resources);
    return (
        <>
            <Container fluid={userPreferences.useFullWidth} className={"wrapper"} id={"mainContain"}>
                <div className={"modal-blocked"}>
                    <Row className="track-map-header">
                        <Col lg={6}>
                            <h3>{getResourceListHeader(mode)}</h3>
                        </Col>
                        <Col lg={6}>
                            <h3>Product Track List</h3>
                        </Col>
                    </Row>

                    <Row xl={12} className="mt-3 track-map">
                        <Col lg={6} className="table-container--scroll">
                            <FileList />
                        </Col>
                        <Col lg={6} className="table-container--scroll ps-3">
                            <DdpTrackList />
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
