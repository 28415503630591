import {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {detect} from "detect-browser";

export const BrowserSupportModal = () => {
    const browser = detect();
    let isSupported = false;

    let name = browser?.name ?? "Unknown Browser";

    if (name === "edge-chromium") {
        if (navigator.userAgent.indexOf("WOW64") === -1) {
            isSupported = true;
        } else {
            isSupported = false;
            name = "Edge (32-bit)";
        }

        isSupported = true;
    }

    if (name === "chrome") {
        if (navigator.userAgent.indexOf("WOW64") === -1) {
            isSupported = true;
        } else {
            isSupported = false;
            name = "Chrome (32-bit)";
        }
    }

    const [settingsVisible] = useState(!isSupported);
    return (
        <>
            <Modal show={settingsVisible}>
                <Modal.Header>
                    <Modal.Title><h3>This browser is not supported.</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        The browser "{name}" is not supported by this application.<br />
                        <br />

                        You must use one of the following browsers:
                        <ul>
                            <li>Chrome (64-bit version)</li>
                            <li>Microsoft Edge (64 bit version)</li>
                        </ul>

                        <br />
                        You may need to contact Application Support to have Chrome 64 installed on your computer.
                        <br />
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};
