import * as React from "react";
import {Table} from "react-bootstrap";
import {useDroppable} from "@dnd-kit/core";
import {DeleteAllButton, UploadAllButton} from "../../../../Buttons/Buttons";
import { getProductType } from "../../../../validation/productTypeValidator";
import { ProductTypeEnum } from "../../../../validation/productTypeEnum";
import {Resource} from "../../../../api";
import {useProjects} from "../../../../Behaviors/projects";
import { ValidationInstructionIcon } from "../../../../StatusIcons/Icons";
import { getResourceListMode, isResourceMapped, ResourceMode } from "../../../../Behaviors/projectTools";
import { useStore } from "../../../../State/zustandStore";
import {DraggableAudioResource} from "./DraggableAudioResource";

export const AudioResourceList = () => {
    const store = useStore().projects;
    const projectId = store.selectedProjectId;
    const drop = React.useRef<HTMLTableSectionElement>(null); // <--- Provide the type of ref element
    const { project } = useProjects(projectId);
    const {setNodeRef} = useDroppable({
        id: -1,
    });

    const productType = getProductType(project!);
    const multipleTrackGroups = productType === ProductTypeEnum.Vinyl || productType === ProductTypeEnum.Cassette;
    const mode = getResourceListMode(project?.resources ?? []);

    function getUnmappedResources(): Resource[] {
        const x = project?.resources?.filter(x => !isResourceMapped(x, project)).values() ?? [];
        return Array.from(x!);
    }

    const y = getUnmappedResources();
    return (
        <>
            <Table role={"list"}
                aria-labelledby="resources-heading"
                className={"audio-resource-list mb-0 " }
                ref={setNodeRef}
                size="sm">
                <tbody ref={drop}>
                    { mode !== ResourceMode.Unknown &&
                    y.map(item => {
                        return (
                            <DraggableAudioResource
                                projectId={projectId!}
                                key={item.id!}
                                isMapped={false}
                                hideTypeIcon={multipleTrackGroups}
                                audioResource={item as Resource} />
                        );
                    })
                    }
                    { mode === ResourceMode.Unknown &&
                    <tr>
                        <td>
                            <ValidationInstructionIcon />
                        </td>
                        <td className={"track-validation-instruction"}>Drop the folder or files here for ingestion.</td>
                    </tr>
                    }

                    <tr className={"filler-row"}><td colSpan={9} /></tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={7} />
                        <td style={{width: 26}}>{mode & ResourceMode.TrackAsset ? <UploadAllButton /> : <></>}</td>
                        <td style={{width: 26}}>{mode ^ ResourceMode.Unknown ? <DeleteAllButton /> : <></>}</td>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
};
