import {Table} from "react-bootstrap";
import { Fragment } from "react";
import {ValidationMessageTypeIcon} from "../StatusIcons/ValidationMessageTypeIcon";
import {useValidation} from "../validation/validation";
import { IngestionMessage, MessageSource } from "../asset-service-api";
import { useProjects } from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

const getMessageKey =
    (
        message: IngestionMessage |
        {
            message: string;
            messageType: string;
            timeStamp?: string | undefined;
            details?: string | null | undefined;
            source?: MessageSource | undefined;
        }) => {
        return `${message.source?.id}:${message.timeStamp}:${message.source?.target}:${message.message}`;
    };

export const Messages = () => {
    const {getValidationErrors, getValidationRestrictions, getValidationWarnings, getPendingUploadMessages} = useValidation();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);
    const messages =
        getPendingUploadMessages(project?.jobId)
            .concat(getValidationErrors(project?.jobId))
            .concat(getValidationRestrictions(project?.jobId))
            .concat(getValidationWarnings(project?.jobId));
    const filteredMessages = Array.from(messages).filter(x => x.messageType !== "Information" && x.source?.scope !== "URI");
    const jobId = project?.jobId;
    return (
        <>
            <h3> Errors / Restrictions / Warnings  </h3>
            <Table className={"error-messages"} role={"list"} responsive="xl" size="sm">
                <tbody>
                    <tr className={"shift-hidden"}>
                        <td colSpan={6}>Job Id: {jobId}</td>
                    </tr>
                    {
                        filteredMessages.map(message => {
                            return (
                                <Fragment key={getMessageKey(message)}>
                                    <tr>
                                        <td className={"iconic"}>
                                            <ValidationMessageTypeIcon messageType={message.messageType!} />
                                        </td>
                                        <td style={{width: "50"}}>
                                            {message.source?.scope}
                                        </td>
                                        <td className={"shift-hidden"}>
                                            {message.source?.id}
                                        </td>
                                        <td className={"shift-hidden"}>
                                            {message.source?.name}
                                        </td>
                                        <td className={"shift-hidden"}>
                                            {message.timeStamp}
                                        </td>
                                        <td style={{width: "50"}} >
                                            {message.source?.target}
                                        </td>
                                        <td style={{width: "100%"}}>
                                            {message.message}
                                        </td>
                                    </tr>
                                    {message.details ? <tr className={"shift-hidden"}>
                                        <td>{message.details}</td>
                                    </tr>
                                        : null}
                                </Fragment>
                            );
                        })}
                    <tr className={"filler-row"}><td> </td></tr>

                </tbody>
            </Table>
        </>
    );
};
